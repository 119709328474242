import { useAuthStore } from '@/stores/auth/auth';
import { checkIfIframe } from '@/utils/checkIfIframe';
import config from '../config';
import i18n from '../i18n/i18n';
import AnotherUserPage from '../pages/AnotherUserPage.vue';
import ExpiredUserPage from '../pages/ExpiredUserPage.vue';
import ExternalProvider from '../pages/ExternalProvider.vue';
import LoginPage from '../pages/LoginPage.vue';
import Logout from '../pages/LogoutPage.vue';
import NoRightsPage from '../pages/NoRightsPage.vue';
import PasswordRestore from '../pages/PasswordRestore.vue';
import { isExactFeature, SSO_TEST_FEATURE } from '../utils/envCheckers';
const defaultLoginProvider = config.DEFAULT_LOGIN_PROVIDER;
const redirectPath = '/login';
const routes = [
    {
        path: '/',
        redirect: redirectPath,
    },
    {
        path: '/logout/:force?',
        component: Logout,
        name: 'logout',
    },
    {
        path: '/login',
        component: LoginPage,
        name: 'login',
        props: { shouldRedirectToDefaultProvider: Boolean(defaultLoginProvider) },
    },
    {
        path: '/password-restore',
        name: 'password-restore',
        component: PasswordRestore,
    },
    {
        path: '/auth/:providerName',
        name: 'auth-provider',
        component: ExternalProvider,
    },
    {
        path: '/session-expired',
        name: 'session-expired',
        redirect: (to) => {
            const { anotherUser, noRights } = to.query;
            if (!anotherUser && !noRights) {
                return ({ path: '/expired-token' });
            }
            else if (anotherUser) {
                return ({ path: '/another-user' });
            }
            return ({ path: '/no-rights' });
        },
    },
    {
        path: '/expired-token',
        name: 'expired-token',
        component: ExpiredUserPage,
    },
    {
        path: '/no-rights',
        name: 'no-rights',
        component: NoRightsPage,
    },
    {
        path: '/another-user',
        name: 'another-user',
        component: AnotherUserPage,
    },
    {
        path: '/multi-user/list-users',
        name: 'multi-user-list-users',
        meta: {
            authMult: true,
        },
        component: () => import(/* webpackChunkName: 'multiUserList' */ '../pages/UserList.vue'),
    },
    {
        path: '/multi-user/set-password',
        name: 'multi-user-set-password',
        component: () => import(/* webpackChunkName: 'multiUserSetPassword' */ '../pages/CreateProfile.vue'),
    },
    {
        path: '/multi-user/set-profile',
        name: 'multi-user-set-profile',
        component: () => import(/* webpackChunkName: 'multiUserSetPassword' */ '../pages/SetProfile.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: 'profile' */ '../layouts/TheWrapper.vue'),
        meta: {
            auth: true,
            iframeEventRequired: true,
        },
        children: [
            {
                meta: {
                    authMult: true,
                    title: (() => i18n.global.t('profile.routes.Profile'))(),
                    allowSuspended: true,
                },
                path: '',
                name: 'profile-my',
                component: () => import(/* webpackChunkName: 'profileMy' */ '../pages/UserSettings.vue'),
            },
            {
                meta: {
                    authMult: true,
                    title: (() => i18n.global.t('profile.routes.Security'))(),
                    allowSuspended: true,
                },
                path: 'security',
                name: 'profile-security',
                component: () => import(/* webpackChunkName: 'profileSecurity' */ '../pages/SecuritySettings.vue'),
            },
        ],
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: 'profile' */ '../layouts/TheWrapper.vue'),
        meta: {
            auth: true,
            iframeEventRequired: true,
        },
        children: [
            {
                path: '',
                name: 'account-home',
                redirect: () => ({ path: 'account/activity' }),
            },
            {
                meta: {
                    title: (() => i18n.global.t('profile.routes.Activity'))(),
                    authMult: true,
                    allowSuspended: true,
                },
                path: 'activity',
                name: 'profile-activity',
                component: () => import(/* webpackChunkName: 'profileSecurity' */ '../pages/ActivityPage.vue'),
                beforeEnter: (to, from, next) => {
                    if (!config.NOTIFICATIONS_API_URL.length) {
                        next('/account/members');
                    }
                    next();
                },
            },
            {
                meta: {
                    authMult: true,
                    title: (() => i18n.global.t('profile.routes.Members'))(),
                },
                path: 'members',
                name: 'profile-members',
                component: () => import(/* webpackChunkName: 'profileManageUsers' */ '../pages/ManageUsers.vue'),
                beforeEnter: (to, from, next) => {
                    const authStore = useAuthStore();
                    if (authStore.role === 'GUEST') {
                        next('/account');
                    }
                    next();
                },
            },
            {
                meta: {
                    title: (() => i18n.global.t('profile.routes.Keys'))(),
                    authMult: true,
                },
                path: 'keys',
                name: 'profile-keys',
                component: () => import(/* webpackChunkName: 'profileManageKeys' */ '../pages/ManageKeys.vue'),
                beforeEnter: (to, from, next) => {
                    const authStore = useAuthStore();
                    if (authStore.role === 'GUEST') {
                        next('/account');
                    }
                    next();
                },
            },
            {
                meta: {
                    title: (() => i18n.global.t('profile.routes.Payments'))(),
                    authMult: true,
                    allowSuspended: true,
                },
                path: 'payments',
                name: 'profile-payments',
                component: () => import(/* webpackChunkName: 'profilePayments' */ '../pages/PaymentsPage.vue'),
                beforeEnter: (to, from, next) => {
                    const authStore = useAuthStore();
                    if (!config.ENABLE_PAYMENTS || !authStore.isPayable || authStore.role === 'GUEST') {
                        next('/account');
                    }
                    next();
                },
            },
        ],
    },
    {
        path: '/multi-user/change-email',
        name: 'multi-user-change-email',
        component: () => import(/* webpackChunkName: 'multiUserChangeEmail' */ '../pages/ChangeEmail.vue'),
    },
    {
        path: '/multi-user/sso',
        name: 'multi-user-sso',
        component: () => import(/* webpackChunkName: 'multiUserSSO' */ '../pages/SsoPage.vue'),
    },
    {
        path: '/multi-user/sso-error',
        name: 'multi-user-sso-error',
        component: () => import(/* webpackChunkName: 'multiUserSSO' */ '../pages/SsoError.vue'),
    },
    {
        path: '/activity',
        name: 'activity',
        meta: {
            auth: true,
        },
        redirect: () => {
            return ({ path: 'account/activity' });
        },
    },
    {
        path: '/or-auth',
        name: 'or-auth',
        meta: {
            authMult: true,
        },
        component: () => import(/* webpackChunkName: 'orAuth' */ '../pages/OrAuth.vue'),
    },
    // catch all redirect
    {
        path: '/:pathMatch(.*)*',
        redirect: redirectPath,
    },
];
routes.push({
    path: '/login-admin',
    component: LoginPage,
    name: 'login-admin',
    props: {
        shouldRedirectToDefaultProvider: false,
        showGoogleSSo: true,
    },
});
if (isExactFeature(SSO_TEST_FEATURE)) {
    routes.push({
        path: '/oauth_idp',
        name: 'oauth-idp',
        component: () => import(/* webpackChunkName: 'saml-testing' */ '../pages/OAuthProvider.vue'),
    });
}
if (checkIfIframe()) {
    routes.push(...[
        {
            path: '/iframe',
            name: 'iframe',
            meta: {
                iframeEventRequired: true,
            },
            component: () => import(/* webpackChunkName: 'iframeLoader' */ '../pages/IframeLoader.vue'),
        },
        {
            path: '/validate-payment',
            name: 'validate-payment',
            component: () => import(/* webpackChunkName: 'iframeLoader' */ '../pages/PaymentsValidationIframe.vue'),
        },
    ]);
}
export default routes;
